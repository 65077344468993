<template>
  <div class="content_box">
    <div class="inner_max">
      <Card>
        <div class="main text-center pt-3 pb-3">
          <i class="el-icon-warning-outline mb-3"></i>
          <div class="mb-5">
            {{ $t('register.authority.dialog1') }}<br />
            <br />
            {{ $t($platform.geti18n(regulator, 'authority.dialog2')) }}
          </div>
          <router-link :to="{ name: 'register' }" class="el-button text-uppercase bg-primary" data-testid="register">
            {{ $t('register.authority.openLiveAcc') }}
          </router-link>
        </div>
      </Card>
    </div>
  </div>
</template>

<script>
import Card from '@/components/Card';

export default {
  components: { Card }
};
</script>
